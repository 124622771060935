.banner-simple{
  $border-color:        $base-border-color;
  $title-color:         #000;
  $desc-color:          #000;
  $arrow-bg:            rgba(0, 0, 0, 0.129);
  $arrow-bg-hover:      rgba(0, 0, 0, 0.2);
  $arrow-color:         rgb(144, 144, 143);
  $arrow-color-hover:   rgb(144, 144, 143);
  $dots-border-color:   $base-border-color;
  $dots-bg:             #fff;
  $dots-bg-active:      $base-border-color;


  border: 1px solid $border-color;
  position: relative;
  font-size: 1rem;
  overflow: hidden;
  
  /* Only one banner is visible while js is loading */
  [data-slider-nojs]{
    [data-slider-slide]:not(:first-child){
      display: none;
    }
  }

  &__item{ 
    position: relative;    
  }  

  &__link{    
    @include position(absolute, 0 0 0 0);
    opacity: 0;
    z-index: $z-index-l;
    background-color: transparent;
    background-color: rgba(0,0,0,0);
  }

  &__image{
    display: block;
    margin: auto;
  }

  &__content{
    text-align: center;
    @include position(absolute, 20px 0 null 0);
    padding: 10px;
    color: $desc-color;
    line-height: 1.1;
    font-weight: bold;

    @media(min-width: $screen-md-min){
      font-size: 1.2em;
    } 

  }

  &__arrow{
    position: absolute;
    width: 40px;
    top: 0;
    bottom: 0;
    z-index: $z-index-s;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    @media (min-width: $screen-md-min){
      background-color: $arrow-bg;
    }
    color: $arrow-color;

    &:hover{
      @media (min-width: $screen-md-min){
        background-color: $arrow-bg-hover;
      }
      color: $arrow-color-hover;
    }

    &.slick-disabled{
      display: none !important;
    }

    &--prev{
      left: 0;
    }

    &--next{
      right: 0;
    }
  }

  &__icon {
    @include absolute-center-translate;
  }

  .slick-dots{    
    position: absolute;
    bottom: 5%;   
    width: 100%;
    text-align: center;
  }
  .slick-dots li{
    position: relative;
    display: inline-block;
    margin: 0 3px;
  }
  .slick-dots button{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 12px;
    cursor: pointer;
    color: transparent;
    border: 1px solid $dots-border-color;
    background-color: $dots-bg;
  }
  .slick-dots .slick-active button{
    background-color: $dots-bg-active;
  }
}