.content__remeshki {
	display:flex;

	&-content {
		flex-grow: 1;
		background-color: #F2F2F2;
		padding: 20px;
	}
	&-desc {
		font-family: Arial, sans-serif;

		h2 {
			font-size: 21px;
			font-weight: bold;
			line-height: 1;
			padding-bottom: 10px;
			color: #000000;
		}
		h3 {
			font-size: 14px;
			padding-bottom: 15px;
			color: #000000;
		}
		p {
			font-size: 13px;
			padding-bottom: 10px;
			color: #999999;
		}
	}

	&-search {
		input {
			border: none;
			height: 36px;
			padding: 7px 14px;
		}
		.input-group-btn {

		}
		.btn {
			padding: 10px 25px;
			border-color: transparent;
			background: linear-gradient(0deg, #E3A428, #E3A428), #FFFFFF;
		}
		.btn__icon--search {
			width: 15px;
			height: 15px;
			color: #ffffff;
		}
	}

	&-image {
		width: 305px;

		img {
			display: block;
			object-fit: cover;
		}
	}
	@media (max-width: $screen-sm-max){
		flex-wrap: wrap;

		&-search {
			input {
				font-size: 13px;
			}
		}

		&-image {
			width: 100%;
		}
	}
	@media (max-width: $screen-xs){

		&-search {
			input {
				font-size: 11px;
				padding: 7px;
			}
		}
	}
}
