.product-parametrs {
	&__item {
		margin-top: 10px;
		display: table;
		table-layout: fixed;
		width: 300px;
	}
	&__name,
	&__field {
		display: table-cell;
	}	
	&__name {
		width: 120px;
	}
}