.straps {
  border: 1px solid #F2F2F2;

  &-product {
    &__title {
      font-family: Arial, sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      color: #000000;
    }
    &__header {
      background-color: #F2F2F2;
      padding: 15px;
    }
  }

  &__inner {
    display: flex;

    &-image {
      padding: 30px 40px;
      min-width: 280px;
    }

    &-content {
      padding: 30px 40px;
    }

    &-title {
      font-size: 28px;
      line-height: 1;
      color: #000000;
      padding-bottom: 20px;
    }

    @media (max-width: $screen-xs-max) {
      flex-wrap: wrap;

      &-content {
        padding: 30px 20px;
      }
    }
  }

  &-properties {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    &__item {
      display: flex;
      width: calc(50% - 35px);
      border-bottom: 1px solid #DCDCDC;
      padding: 7px 0;

      &:nth-child(even) {
        margin-left: 70px;
      }
    }
    &__item > * {
      width: 50%;
    }

    &__header {
      color: #000000;
      font-weight: bold;
    }
    &__value {
      font-size: 16px;
      color: #666666;
    }

    @media (max-width: $screen-sm-max) {
      &__item {
        width: calc(50% - 15px);
        &:nth-child(even) {
          margin-left: 30px;
        }
      }
    }
    @media (max-width: $screen-xs-max) {
      &__item {
        width: 100%;
        &:nth-child(even) {
          margin-left: 0;
        }
      }
    }
  }

  &__initial-contnet {
    font-size: 0;
    display: none;
  }

  &-bottom {
    padding-top: 70px;

    .product-intro {
      &__original {
        padding-top: 4px;
      }
      &__original-text {
        display: inline-block;
        font-size: 13px;
        padding-left: 22px;
        color: #94C337;
        position: relative;
      }
      &__original-text:before {
        display: block;
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #94C337;
        left: 0;
        top: 3px;
      }
    }
    .product-intro__no-original {
      .product-intro__original-text {
        color: #C38337 !important;
        &:before {
          background-color: #C38337 !important;
        }
      }
    }
  }

  &-products__separator {
    font-size: 18px;
    line-height: 24px;
    color: #000000;

    & > * {
      background-color: #F2F2F2;
      padding: 25px 30px 40px;
      min-height: 100px;
    }

    &-top {
      margin-bottom: 30px;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 90px;
        height: 20px;
        bottom: 15px;
        left: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCA4OCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNjYuOTA5IDguMzk5QzU5LjkwNiA4LjM4NCA1Mi45MDUgOC4yNyA0NS45MSA3Ljk3OUMzOC45MTUgNy42OTkgMzEuOTI2IDcuMzEzIDI0Ljk0NiA2LjgxNkMxOS44MTUgNi40NDcgMTQuNjc3IDYuMDI5IDkuNTYxIDUuNTM4QzExLjYwOCA1LjEzMyAxMy42NDIgNC42NjQgMTUuNjc1IDQuMTg5QzE3Ljg0MiAzLjY1NSAyMCAzLjA3NyAyMi4xMzcgMi40MTZDMjQuMjY2IDEuNzQ0IDI2LjQwNCAxLjAyNCAyOC4zOTcgMEMyNi4yMzYgMC41NjYgMjQuMDY2IDAuOTkzIDIxLjg4MiAxLjM5NEMxOS43MDEgMS43OTQgMTcuNTIxIDIuMTkzIDE1LjMzNCAyLjU2N0wyLjIzNyA0Ljg4M0wwIDUuMjc5TDEuNzkyIDYuNjIxQzMuMjcyIDcuNzI5IDQuNzg0IDguNzk5IDYuNDAyIDkuNjg1QzcuMTkyIDEwLjE1OSA4LjAxOCAxMC41NjcgOC44MzMgMTAuOTkyQzkuNjcgMTEuMzczIDEwLjQ5NiAxMS43NzYgMTEuMzQ5IDEyLjExNUMxMy4wMzggMTIuODMgMTQuNzc3IDEzLjQxNiAxNi41MzcgMTMuOTAxQzE4LjI5OCAxNC4zODEgMjAuMDg3IDE0Ljc2OCAyMS44OTggMTQuOTVDMTguNDUxIDEzLjgxOSAxNS4xMzMgMTIuNDI5IDExLjk2MiAxMC43OUMxMC40MTQgOS45OTYgOC45MDYgOS4xMzMgNy40NDQgOC4yMDlDMTMuMjQ0IDguNTc4IDE5LjAyOSA4Ljg1MyAyNC44MjYgOS4wNjNDMzEuODQyIDkuMzEyIDM4Ljg2MSA5LjQ0OCA0NS44NzggOS40NzlDNTIuODk1IDkuNTIgNTkuOTEzIDkuMzg0IDY2LjkyMyA5LjE0OUM3My45MzQgOC44OTYgODAuOTM0IDguNDM2IDg3LjkxNCA3LjgwOEM4MC45MTUgOC4xODYgNzMuOTEzIDguMzk1IDY2LjkwOSA4LjM5OVoiIGZpbGw9IiMzNzdCQjUiLz48L3N2Zz4=);
      }
    }
    &-bottom {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 110px;
        height: 20px;
        bottom: 15px;
        right: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA2IiBoZWlnaHQ9IjE3IiB2aWV3Qm94PSIwIDAgMTA2IDE3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDEyLjIzMkM4LjM2NyAxMi40ODEgMTYuNzM3IDEyLjU2MSAyNS4xMDMgMTIuNTE5QzMzLjQ2OSAxMi40NzMgNDEuODMzIDEyLjI5MSA1MC4xODIgMTEuOTAyQzU4LjUyOCAxMS41MDQgNjYuODY5IDEwLjkyMSA3NS4xMjggOS44NzQwMUM3OS4yNTMgOS4zMzcwMSA4My4zNjIgOC42OTQwMSA4Ny4zODUgNy43ODkwMUM5MS4zNzcgNi44NzEwMSA5NS40MDcgNS43MjEwMSA5OC43MjQgMy43ODEwMUwxMDAuMjc0IDYuMzQ5MDFDOTYuMjcxIDguNTIzMDEgOTIuMDg4IDkuNTEyMDEgODcuOTI3IDEwLjM1OEM4My43NTYgMTEuMTY1IDc5LjU2OSAxMS42OTEgNzUuMzggMTIuMTFDNjcgMTIuOTE4IDU4LjYxMiAxMy4yNTIgNTAuMjMgMTMuNDAxQzQxLjg0NyAxMy41NDEgMzMuNDY4IDEzLjQ3MyAyNS4wOTYgMTMuMjY4QzE2LjcyNCAxMy4wNjIgOC4zNTYgMTIuNzMxIDAgMTIuMjMyWiIgZmlsbD0iIzM3N0JCNSIvPjxwYXRoIGQ9Ik04MC4yNSAxLjA2NUw4NS44NDkgMS40MjdDODcuNjk5IDEuNTM3IDg5LjUzNiAxLjY0NyA5MS4zNjIgMS42OEM5NC45OTcgMS43NTQgOTguNjUyIDEuNzEgMTAxLjk3MSAwLjg4NEwxMDUuNTI0IDBMMTAzLjY0MSAzLjA3MUMxMDIuODYzIDQuMzQgMTAyLjA2MSA1LjU2IDEwMS4yMjEgNi43NzJDMTAwLjM4MSA3Ljk4MSA5OS40OTEgOS4xNTcgOTguNTY2IDEwLjMwOUM5Ny42NSAxMS40NjcgOTYuNjYgMTIuNTc3IDk1LjYxIDEzLjYzNUM5NS4wNzQgMTQuMTU1IDk0LjUzOSAxNC42NzcgOTMuOTQ0IDE1LjE0QzkzLjM1OSAxNS42MDUgOTIuNzMgMTYuMDU3IDkyLjAyOCAxNi4zMTZDOTMuMDc1IDE1LjI4IDkzLjc4OSAxNC4wMjMgOTQuNTYxIDEyLjgwM0M5NS4zMTkgMTEuNTc0IDk2LjAyNiAxMC4zMTIgOTYuNzYxIDkuMDY5TDEwMS4wODIgMS41NjJMMTAyLjc1MSAzLjc0OUMxMDAuNzk5IDQuMjg0IDk4LjgzMSA0LjUwOCA5Ni44OTMgNC41MzZDOTQuOTU0IDQuNTc1IDkzLjAzOCA0LjQyMiA5MS4xNTIgNC4xNTNDODkuMjY3IDMuODc0IDg3LjQwOSAzLjQ4NyA4NS41ODYgMi45ODFDODMuNzY4IDIuNDYxIDgxLjk2OCAxLjg3NyA4MC4yNSAxLjA2NVoiIGZpbGw9IiMzNzdCQjUiLz48L3N2Zz4=);
      }
    }

    @media (max-width:$screen-xs-max) {
      font-size: 15px;
      line-height: 20px;

      & > * {
        padding: 25px 15px 40px;
      }
      &-top {
        &:after {
          left: 15px;
         }
      }
      &-bottom {
        &:after {
          right: 15px;
        }
      }
    }
  }
}