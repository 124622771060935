$output-bourbon-deprecation-warnings: false !default;
//Bootstrap variables and mixins
@import "bourbon";
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

//Project variables and mixins
@import "_core/variables.scss";
@import "_core/mixins.scss";
//Project Reset
@import "_core/reset.scss";

// Bootstrap Reset and dependencies
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Bootstrap Core CSS
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";


/*
--------------------------------------------------
Bootstrap grid
--------------------------------------------------
*/
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";

/*
--------------------------------------------------
Bootstrap forms
--------------------------------------------------
*/
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";

/*
--------------------------------------------------
Bootstrap buttons
--------------------------------------------------
*/
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Bootstrap Components
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
/*
--------------------------------------------------
Bootstrap button-groups
--------------------------------------------------
*/
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
/*
--------------------------------------------------
Bootstrap input-groups
--------------------------------------------------
*/
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Bootstrap Components w/ JavaScript
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";


/*
--------------------------------------------------
Bootstrap Utility classes
--------------------------------------------------
*/
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";


/*
--------------------------------------------------
Magnific popup Library
--------------------------------------------------
*/
@import "../bower_components/magnific-popup/src/css/main.scss";





/*
--------------------------------------------------
Project Components
--------------------------------------------------
*/
@import "_core/base.scss";

@import "globals/link";

@import "icons/sprite.scss";
@import "icons/icons.scss";
@import "svg/svg-icon.scss";

@import "scaffolding/page.scss";
@import "scaffolding/content.scss";
@import "scaffolding/footer.scss";
@import "scaffolding/basement.scss";

@import "offline/offline.scss";

@import "frames/frame-content/frame-content.scss";

@import "grid/grid.scss";
@import "typo/typo.scss";

@import "navs/list-nav/list-nav.scss";
@import "navs/tree-nav/tree-nav.scss";
@import "navs/table-nav/table-nav.scss";
@import "navs/cols-nav/cols-nav.scss";
@import "navs/mobile-nav/mobile-nav.scss";
@import "navs/sidebar-nav/sidebar-nav.scss";
@import "navs/search-nav/search-nav.scss";
@import "navs/overlay/overlay.scss";

@import "user-panel/user-panel.scss";

@import "modal/modal.scss";

@import "helpers/site-info/site-info.scss"; 
@import "helpers/soc-groups/soc-groups.scss";
@import "helpers/pay-icons/pay-icons.scss";
@import "helpers/star-rating/star-rating.scss";
@import "helpers/star-voting/star-voting.scss";
@import "helpers/messages/messages.scss";
@import "helpers/likes/likes.scss";
@import "helpers/shares/shares.scss";
@import "helpers/tooltip/tooltip.scss";
@import "helpers/loaders/loader-frame.scss";
// @import "helpers/loaders/spinner-fa/spinner-fa.scss";
@import "helpers/loaders/spinner-circle/spinner-circle.scss";

@import "benefits/benefits.scss";
@import "banners/banner-simple/banner-simple.scss";
@import "buttons/buttons.scss";
@import "navs/board-nav/board-nav.scss";
@import "widgets/widget-primary/widget-primary.scss";
@import "widgets/widget-secondary/widget-secondary.scss";
@import "widgets/widget-sidebar/widget-sidebar.scss";
@import "post/post.scss";
@import "small-post/small-post";
@import "brands/bradns-widget/brands-widget.scss";
@import "brands/brands-images/brands-images.scss";
@import "brands/brand-sidebar/brand-sidebar";
@import "seo-text/seo-text.scss";
@import "breadcrumbs/breadcrumbs.scss";
@import "paginator/paginator.scss";
@import "comments/comments.scss";

@import "form/form.scss";
@import "form/form-input.scss";
@import "form/autocomplete/autocomplete.scss";
@import "form/range-slider/range-slider.scss";

@import "catalog/catalog-toolbar/catalog-toolbar.scss";
@import "catalog/filter/filter.scss";
@import "catalog/active-filters/active-filters.scss";
@import "catalog/catalog-section/catalog-section.scss";

@import "product/product-cut/product-cut.scss";
@import "product/product-thumb/product-thumb.scss";
@import "product/product-intro/product-intro.scss";
@import "product/product-photo/product-photo.scss";
@import "product/product-variants/variants-radio/variants-radio.scss";
@import "product/product-variants/variants-select/variants-selct";
@import "product/properties/properties.scss";
@import "product/product-price/product-price.scss";
@import "product/product-kit/product-kit.scss";
@import "product/product-shipping/product-shipping.scss";
@import "product/product-fullinfo/product-fullinfo.scss";
@import "product/product-actions/product-actions";
@import "product/product-sales/product-sales";
@import "product/product-parametrs/product-parametrs.scss";


@import "cart/cart-frame/cart-frame.scss";
@import "cart/cart-summary/cart-summary.scss";
@import "cart/delivery-radio/delivery-radio.scss";
@import "cart/cart-product/cart-product.scss";
@import "cart/order-details/order-details.scss";
@import "cart/cart-price/cart-price.scss";
@import "cart/cart-header/cart-header.scss";

@import "profile/order-history/order-history.scss";
@import "profile/discount-info/discount-info.scss";
@import "profile/discount-table/discount-table.scss";

@import "compare/compare.scss";

@import "gallery/gallery-album/gallery-album.scss";
@import "gallery/gallery-image/gallery-image.scss";
@import "gallery/gallery-menu/gallery-menu.scss";

@import "countdown/countdown-post";
@import "countdown/countdown-product";

@import "product/thumbs-slider/_thumbs-slider.scss";
@import "content-remeshki/content-remeshki";

@import "straps/straps";
@import "search/search";