// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$-russia-name: 'Russia';
$-russia-x: 108px;
$-russia-y: 45px;
$-russia-offset-x: -108px;
$-russia-offset-y: -45px;
$-russia-width: 16px;
$-russia-height: 16px;
$-russia-total-width: 126px;
$-russia-total-height: 106px;
$-russia-image: '../_img/sprite.png';
$-russia: (108px, 45px, -108px, -45px, 16px, 16px, 126px, 106px, '../_img/sprite.png', 'Russia', );
$-ukraine-name: 'Ukraine';
$-ukraine-x: 108px;
$-ukraine-y: 61px;
$-ukraine-offset-x: -108px;
$-ukraine-offset-y: -61px;
$-ukraine-width: 16px;
$-ukraine-height: 16px;
$-ukraine-total-width: 126px;
$-ukraine-total-height: 106px;
$-ukraine-image: '../_img/sprite.png';
$-ukraine: (108px, 61px, -108px, -61px, 16px, 16px, 126px, 106px, '../_img/sprite.png', 'Ukraine', );
$-united-states-name: 'United-States';
$-united-states-x: 0px;
$-united-states-y: 90px;
$-united-states-offset-x: 0px;
$-united-states-offset-y: -90px;
$-united-states-width: 16px;
$-united-states-height: 16px;
$-united-states-total-width: 126px;
$-united-states-total-height: 106px;
$-united-states-image: '../_img/sprite.png';
$-united-states: (0px, 90px, 0px, -90px, 16px, 16px, 126px, 106px, '../_img/sprite.png', 'United-States', );
$hit-name: 'hit';
$hit-x: 63px;
$hit-y: 45px;
$hit-offset-x: -63px;
$hit-offset-y: -45px;
$hit-width: 45px;
$hit-height: 45px;
$hit-total-width: 126px;
$hit-total-height: 106px;
$hit-image: '../_img/sprite.png';
$hit: (63px, 45px, -63px, -45px, 45px, 45px, 126px, 106px, '../_img/sprite.png', 'hit', );
$hot-price-name: 'hot-price';
$hot-price-x: 0px;
$hot-price-y: 0px;
$hot-price-offset-x: 0px;
$hot-price-offset-y: 0px;
$hot-price-width: 63px;
$hot-price-height: 45px;
$hot-price-total-width: 126px;
$hot-price-total-height: 106px;
$hot-price-image: '../_img/sprite.png';
$hot-price: (0px, 0px, 0px, 0px, 63px, 45px, 126px, 106px, '../_img/sprite.png', 'hot-price', );
$new-name: 'new';
$new-x: 63px;
$new-y: 0px;
$new-offset-x: -63px;
$new-offset-y: 0px;
$new-width: 63px;
$new-height: 45px;
$new-total-width: 126px;
$new-total-height: 106px;
$new-image: '../_img/sprite.png';
$new: (63px, 0px, -63px, 0px, 63px, 45px, 126px, 106px, '../_img/sprite.png', 'new', );
$special-offers-name: 'special-offers';
$special-offers-x: 0px;
$special-offers-y: 45px;
$special-offers-offset-x: 0px;
$special-offers-offset-y: -45px;
$special-offers-width: 63px;
$special-offers-height: 45px;
$special-offers-total-width: 126px;
$special-offers-total-height: 106px;
$special-offers-image: '../_img/sprite.png';
$special-offers: (0px, 45px, 0px, -45px, 63px, 45px, 126px, 106px, '../_img/sprite.png', 'special-offers', );
$spritesheet-width: 126px;
$spritesheet-height: 106px;
$spritesheet-image: '../_img/sprite.png';
$spritesheet-sprites: ($-russia, $-ukraine, $-united-states, $hit, $hot-price, $new, $special-offers, );
$spritesheet: (126px, 106px, '../_img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
