.product-thumb{

  &__photo{
    width: 130px;
    border:  1px solid $product-photo-border-color;
  }


  &__title{
    padding-top: 5px;
    font-size: 0.9rem;

    &-link{
      @include link-main;
    }
  }

  &__price{
    padding-top: 5px;
  }

}