.tooltip{
	$ico-size:   20px;
	$desc-width: 200px;
	
	display: inline;
	position: relative;

	&__label{
		display: inline;
	}

	&__position{
		position: absolute;	
		bottom: -1px;
		right: -($ico-size + 5);
	}

	&__ico{
		position: relative;
		width: $ico-size;
		height: $ico-size;	
		background: $theme-secondary-color;
		border-radius: 50%;

		&:before{
			content: '?';
			@include absolute-center-translate;
			display: block;
			color: #000;
			font-weight: bold;
		}

		&:hover{
			.tooltip__drop{
				display: block;
			}
		}


	}

	&__drop{
		display: none;
		position: absolute;		
		z-index: 10;
		left: 0;
		top: -5px;
		padding-left: $ico-size + 2;
	}

	&__desc{
		border: 1px solid $base-border-color;
		background: #fff;
		font-size: 12px;
		padding: 10px;
		width: $desc-width;
		font-weight: normal;
		font-style: normal;
	}

}