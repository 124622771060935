.post {

  @media (min-width: $screen-sm-min) {
    display: table;

    //firefox max-width for table-cell hack
    table-layout: fixed;
  }

  &--small {
    font-size: 0.9rem;
  }

  &--sale {
    display: block;
  }

  &__image {
    display: block;
    margin-bottom: 5px;
  }

  &__image {
    @media (min-width: $screen-sm-min) {
      display: table-cell;
      vertical-align: top;
      padding-right: 10px;
      width: 30%;
      margin-bottom: 0;
    }

    img{
      font-size: 1rem;
    }
  }

  &--sale &__image{
    display: block;
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
    border: 10px solid $base-border-color;
    font-size: 0;
  }

  &__inner {
    @media (min-width: $screen-sm-min) {
      display: table-cell;
      vertical-align: top;
    }
  }

  &--sale &__inner{
    display: block;
    text-align: center;
  }

  &__date {
    display: block;
    color: $base-font-color-secondary;
    padding-bottom: 3px;
  }

  &__title {

    font-size: 1.3em;

    &-link {
      @include link-main;
    }
  }

  &--sale &__title-link{
    text-transform: uppercase;
    color: $theme-main-color;

    &:hover{
      color: $base-main-link-hover-color;
    }
  }

  &__desc {
    padding-top: 5px;
  }

  &__readmore {
    @include link-main;
    display: block;
    margin-top: 5px;
    white-space: nowrap;

    &:after {
      content: '>';
      margin-left: 5px;
    }

  }

  &__expire {
    display: block;
    padding: 5px 0;
    color: #cc0000;
  }

  &--sale &__expire{
    font-size: 0.9em;
  }
}