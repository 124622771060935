.footer{	
	color: $footer-text-color;	

	&__title{
		font-size: 14px;
		font-weight: bold;
		color: $footer-title-color;
	}

	&__inner{
		margin-top: 10px;
	}

	&__items{

	}

	&__item{
		margin-top: 5px;
	}

	&__link{
		@include link-main;
	}

}