.thumbs-slider {
  $thumbs-visible-count: 4;
  $arrow-width: 4em;

  font-size: 1rem;

  position: relative;
  padding: 2em 0;

  &--hor{
    padding: 0 2em;
  }

  &__list {
  }

  &__item {
    padding: 5px 10px;

    // hide elements before slick had been initialized
    &:not(.slick-slide):nth-child(n + #{$thumbs-visible-count}) {
      display: none;
    }
  }
  &__photo {
    display: block;
    position: relative;
    border: 1px solid $product-photo-border-color;
    width: 100%;
    padding-bottom: $product-photo-fluid-height;

    //transition: opacity $theme-effects-time;

    &[data-product-photo-thumb-active] {
      opacity: 0.5;
    }
  }
  &__imagebox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0.3em;
    padding: 0.3em;

  }
  &__img {
    @include absolute-center-margin;
    max-height: 100%;
  }
  &__arrow {
    position: absolute;

    &:not(&--hor){
      height: $arrow-width/2;
      left: 0;
      right: 0;
      color: $base-font-color;
    }


    &--prev:not(&--hor) {
      top: 0;
    }
    &--next:not(&--hor) {
      bottom: 0;
    }

    /*For horizontal slider*/
    &--hor{
      top: 0;
      bottom: 0;
      height: 100%;
      width: $arrow-width/2;
    }

    &--hor.thumbs-slider__arrow--prev{
      left: 0;
      right: auto;
    }

    &--hor.thumbs-slider__arrow--next{
      right: 0;
      left:auto;
    }

    &.slick-disabled {
      opacity: 0.5;
      //visibility: hidden;
    }
    &:not(.slick-disabled):hover {
      color: $theme-main-color;
      cursor: pointer;
    }
  //  &:not(.slick-disabled):active {
  //    transform: scale(1.2);
  //  }
  }
  &__icon {
    @include absolute-center-margin;
    display: block;
    width: 1.5em;
    height: 1.5em;
  }
}