.cart-price{
  font-size: 0;

  &__old{
    @include clearfix;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;    
    color: #000;
    white-space: nowrap;
    
    /* line-through */
    &:after{
      content: '';
      height: 1px;
      width: 100%;
      background: #000;
      position: absolute;
      top: 50%;
    }

    &-value{
      float: left;
    }

    &-cur{
      float: left;
    }
  }

  &__main{
    @include clearfix;
    display: inline-block;
    vertical-align: middle;    
    font-size: 1.2rem;
    font-weight: bold;    
    white-space: nowrap;
    color: #000;

    &--vertical{
      width: 100%;
      margin-left: 0;
    }

    &--small{
      font-size: 1rem;
    }

    &--lg{
      font-size: 1.5rem;
    }

    &-value{
      float: left;
    }

    &-cur{
      float: left;
    }
  }

  &__addition{
    @include clearfix;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    font-size: 0.9rem;
    color: $base-font-color-secondary;

    &-item{
      float: left;        
      
      &:before{
        content: '/';
        float: left;
        margin: 1px 5px 0 5px;           
      }
    }

    &-value{
      float: left;
    }

    &-cur{
      float: left;
    }
  }

}