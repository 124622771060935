.order-details{
	
	&__group{
		display: table;
		width: 100%;
		table-layout: fixed;
		padding-bottom: 40px;
	}

	&__list{
		display: table-row;		
	}

	&__item{
		display: table-cell;
		vertical-align: top;
		padding-bottom: 10px;

		&:not(:first-child){
			padding-left: 30px;
		}

		&--title{
			font-weight: bold;
		}

	}
}